.component{
    font-family: 'Montserrat' !important;
    padding: 0 10px 0 35px;
  }
  
  .promotion-data {
      background: rgba(248, 248, 248, 0.4);
      box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
      border-radius: 6px;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 3%;
  }
  
  .comparision-percentage {
      display: flex;
      align-items: center;
  }
  
  .arrow-up-icon {
      color: rgba(18, 167, 174, 1) !important;
      background-color: rgba(18, 167, 174, 0.2) !important;
      border-radius: 50% !important;
      font-size: 30px !important;
  }
  
  .recent-scenarios{
      margin-top: 10px;
  }
  
  .glider-tour {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding-left: 2%;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
    }
    
    .glide {
      box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
      padding: 10px !important;
      margin-right: 10px;
      border: 1px solid transparent;
      border-radius: 8px;
      min-width: 45%;
      height: auto;
      transition: 0.5s;
    }
  
  
    .pointer-flex{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  
    .bullet-icon{
      min-width: 10px !important;
    }
  
    #moveBehind{
      transform: rotate(180deg);
    }

    .main-content {
        display: flex;
        height: auto;
        margin: auto;
      }
      
      .list {
        display: flex;
        flex-direction: column;
        font-family: Montserrat !important;
        /* margin-top: 50px; */
      }
      
      .list .MuiInput-underline:before,
      .MuiInput-underline:after {
        border: none !important;
      }
      
      .list label+.MuiInput-formControl {
        background: #1d1d1d !important;
        z-index: -1;
        /* opacity: 0.1 !important;
        box-shadow: 0px 4px 4px rgba(242, 244, 248, 0.25) !important; */
        border-radius: 6px !important;
      }
      
      .list .MuiInputBase-inputTypeSearch{
        color: #FFFFFF !important;
      }
      
      .list .css-960cb9-MuiSvgIcon-root{
        font-size: 1.25em !important;
        margin-right: 10px;
      }

      .MuiSvgIcon-root{
        margin-right: 10px;
      }
      
      .list a,
      .list .list-item {
        font-size: 16px;
        width: 100%;
        display: flex !important;
        align-items: center;
        padding: 10px 0 !important;
        text-decoration: none;
        color: #FFFFFF;
      }
      
      .list a:hover{
        color: #e0e0e0;
        text-decoration: none;
      }
      
      .list .list-item {
         /* cursor: pointer;  */
        color: #FFFFFF !important;
      }
      
      .list .item-text{
        display: flex;
      
      }
      
      .collapse-list{
        color: white !important;
      }
      
      .list .list-item{
        display: flex;
        justify-content: space-between;
      }
      
      
      a .link-logo{
        margin-right: 10px;
        /* color: #FFFFFF; */
        min-width: 0 !important;
      }
      
      .list .link-logo {
        min-width: 0 !important;
        color: #FFFFFF !important;
      
      }

      .list .link-all-tool {
        min-width: 0 !important;
        /* color: #3DD5C8 !important; */
        color: #FFFFFF !important;
        padding-top: 16.5%;
        /* 
        padding-left: 21%; */
        
      }
      
      .list .link-logo .css-i4bv87-MuiSvgIcon-root{
        font-size: 1.25em;
        margin-right: 10px;
      }
      
      /* .nav-tour{
        position: absolute;
        padding-left: 10%;
        display: block;

      } */
      
      .list-contain{
        position: absolute;
        top: 224px;
        height: 231px;
        width: 240px;
        background-color: black;
        border: 1px solid white;
        border-radius: 6px;
        box-shadow: 0px 4px 15px #ffffff40;
}
      
      .sidebar-focus {
        min-height: 100vh;
        padding: 15px;
        background: #000000;
        box-shadow: 0px 4px 21px rgba(221, 222, 226, 0.56);
        overflow: hidden;
        transition: .3s;
        z-index: 2;
        filter: blur(2.5px);
      }

      
      .list .css-1plgs3k-MuiFormControl-root{
        background: #191919 !important;
        border-radius: 15px !important;
        margin: 25px 0px;
      }
      
      .list .MuiInputBase-root{
        color: white !important;
        border-color: transparent !important;
        font-family: 'Montserrat' !important;
      }
      
       .icons-focus {
        color: white;
        position: absolute;
        border: 1px solid black;
        background: #1F1E1E;
        border-radius: 50%;
        z-index: 2;
        filter: blur(2.5px);
      }
      
      .options>a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #423A3A;
      }
      
      
      .content {
        z-index: 1;
        /* min-width: %; */
      }
      
      .sidebar .MuiDivider-root{
        margin:10px 0;
        background-color:#999999;
      }
      
      .static-navbar {
        margin-bottom: 20px;
        padding: 0 0 0 50px;
        width: 85vw;
        height: 10vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 14px 7px 20px 4px rgb(40 41 61 / 8%)
      }
      
      .MuiPaper-root{
        background-color: transparent !important;
      }

     /* .nav-container > svg{
        z-index: 1;
        margin: 5px;
     } */

     /* .quick-link{
      position: absolute;
      top: 466px;
     }

     .quick-link-1{
      position: absolute;
      top: 491px;
     }

     .link-all-tool > svg {

      margin-left: 4px;
     } */