.caraousel-container{
    height: 100vh;
    width: 90vw;
}


.caraousel-content{

    
    /* width: 105.8em; */
    width: 80%;
    display: flex;
    /* background: white; */
    height: 26em;
    /* height: 65%; */
    margin: 10%;
    /* margin-top: 10%; */
    /* position: absolute; */
    overflow: hidden;
}

.content-tour-init{

  text-align: center;
  padding-bottom: 16%;
}

.data-content{
   
    position: relative;
    min-width: 100%;
    align-self: center;
    padding-left: 9%;
    /* padding-top: 31%;
    padding-bottom: 20.2%; */
}

.data-content[vari='1']{
  animation: animate-data 1.6s 1 ease;
  animation-fill-mode: forwards;
  
}

@keyframes animate-data {
  
  2%{
    opacity: 0;
  }

  75%{
    opacity: 0;
  }
  100%{
    transform: translate(-900%);
  }
}

.left-part{
    width: 39%;
    display: flex;
    /* background: #d9c73959; */
    background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
    overflow: hidden;
    /* border: 1px solid white; */
    /* border-radius: 65px; */
    position: relative;
    z-index: 1;
    /* margin-left: 26%; */
    transform: translate(71%);
  /* margin-right: auto; */
   /* animation: 'animate_left' 2s 1 ease ;
   animation-delay: 1.3s;
   animation-fill-mode: forwards; */
  
    
}

.left-part[vari='1']{
 
  
  animation: animate_left 1.1s 1 ease ;
    /* animation-delay: 1.3s; */
    animation-fill-mode: forwards;
}

.left-part[vari='-1']{
 
  
  animation: animate_left_rev 1.1s ease 0s 1 reverse forwards;
    /* animation-delay: 1.3s; */
    /* animation-fill-mode: forwards;
    animation-direction: reverse; */
}

.left-part[vari='2']{
  animation: animate-left-init 1.1s ease 0s 1 forwards;
}


/* .left-part-init{
 
  width: 39%;
    display: flex;
    background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
    overflow: hidden;
    position: relative;
    z-index: 1;


  
} */

.right-part{
    width: 61%;
    /* animation: 'animate_right' 2.8s 1 ease;
    animation-fill-mode: forwards; */
    
}
.right-part[vari='1']{
  
  animation: animate_right 1.2s 1 ease;
  animation-fill-mode: forwards;
  /* animation-direction: reverse; */
  
}
.right-part[vari='-1']{
  
  animation: animate_right_rev 0.9s 1 cubic-bezier(.41,.32,.28,.42);
  animation-fill-mode: forwards;
  animation-direction: reverse;
  
}

.right-part[vari='2']{
  
  animation: animate-right-init 0.9s 1 cubic-bezier(.41,.32,.28,.42);
  animation-fill-mode: forwards;
  /* width: 61%; */
  
}

@keyframes animate-left-init {
  
  100%{
    transform: translate(0%);
  }
}



@keyframes animate_left {
    
  0%{
    transform: translate(0%);
  }
  100% {
    transform: translate(65%);
    
  }
  
} 

@keyframes animate_left_rev {
    
  0%{
    transform: translate(0%);
    
  }
  100% {
    transform: translate(65%);
   
  }
  
} 


@keyframes animate-right-init {
  

  0%{
    transform: translate(-54%);
    opacity: 0;  
  }
  100%{
    transform: translate(0%);
    opacity: 1;
  }
  
}

 @keyframes animate_right {
 
25%{
  opacity: 1;
  transform: translateX(-5%);
  /* transform: scaleX(0); */
}  

35%{
  opacity: 1;
  transform: translate(-10%);
}

/* 55%{
  
} */

60%{
  opacity: 0.4;
  transform: translate(-25%);
}
65%{
  opacity: 0;
}



100%{
  opacity: 0; 
   transform: translate(-60%);
  /* transform: scaleX(0); */
}
 
    
}
@keyframes animate_right_rev {
    
  25%{
    opacity: 1;
    transform: translate(-5%);
  }  
  
  35%{
    opacity: 1;
    transform: translate(-10%);
  }
  
  55%{
    opacity: 0.7;
    transform: translate(-15%);
  }
  
  60%{
    opacity: 0.4;
    transform: translate(-25%);
  }
  65%{
    opacity: 0;
  }
  
  75%{
    opacity: 0;
  }
  85%{
    opacity: 0;
  }
  95%{
    opacity: 0;
  }
  
  100%{
    opacity: 0;
    transform: translate(-60%);
  }
 
}

.left-footer{
    position: absolute;
    display: flex;
    min-width: 100%; /*39%*/
    justify-content: space-evenly;
  padding-bottom: 3%;
  align-self: flex-end;
}

/* .left-footer-1{
    position: absolute;
    display: flex;
    min-width: 39%;
    top:24rem;
    justify-content: center;
  padding-bottom: 3%;
  align-self: flex-end;
} */

/* .ns{
    width: 300%;
    height: 100%;
} */
.dots {
    display: flex;
    /* height: 95vh; */
    justify-content: center;
    align-items: flex-end;
}

.dot-ele {
    width: 10px;
    height: 10px;
    background: #D0D5DD;
    border-radius: 6px;
    flex: none;
    order: 2;
    flex-grow: 0;
    gap: 3px;
    margin: 6px;
    cursor: pointer;
}

.dot-ele-active {
    width: 10px;
    height: 10px;
    background: #3DD5C8;
    border-radius: 6px;
    flex: none;
    order: 2;
    flex-grow: 0;
    gap: 3px;
    margin: 6px;
}


.arrows{
    display: flex;
    width: 102%;
    position: absolute;
    top: 50%;
    z-index: 3;
    justify-content: space-between;
    left: -1%;
}

.arrows-startindex{
    display: flex;
    width: 102%;
    position: absolute;
    top: 50%;
    z-index: 3;
    justify-content: end;
    left: -1%;
}


.arrow-left{
    /* position: absolute;
top: 30rem;
left: 6%; */
/* width: 46px;
height: 46px; */
 width: 3em;
height: 3em; 
border: 0px solid rgba(253, 247, 247, 0.29);
border-radius: 50%;
background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
cursor: pointer;
z-index: 2;
}

.arrow-right{
    /* position: absolute; */
/* top: 30rem;
right: 9.4%; */
width: 3em;
height: 3em;
border: 0px solid rgba(253, 247, 247, 0.29);
background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
border-radius: 50%;
cursor: pointer;
}

.hubble-exit-btn{
  opacity: 0;
  
}

.hubble-exit-btn[vari='1']{
  animation:animate-btn 1s 1 ease-in;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
@keyframes animate-btn {

  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
  
}


.hubble-btn{
    /* margin: 10px; */
    padding: 22px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #00a5a5;
    background-image: linear-gradient(45deg, #00a5a5 0%, #00a5a5 51%, #41d7ad 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: small;
  
  
}

.hubble-btn:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: white;
    text-decoration: none;
  
  }
    
  .hubble-btn:active {
    transform: scale(0.99);
  }

  .endLogo{
    padding-left: 29%;
    position: absolute;
    width: 100%;
  }

  .header-content{
    font-size: 2.8vw;
    font-family: 'Montserrat';
    white-space: pre-wrap;
  }


  .carousel-buttons {
    background-color: #00a5a5;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    line-height: 1.15385;
    margin: 0;
    padding: 8px .8em;
    position: relative;
    text-align: center;
  }

  .disabled{

    opacity: 0;
    cursor: default;
    pointer-events: none;
  }

  .skip-btn{
    max-width: 39%;
z-index: 1;
position: absolute;
  }
  /* .buttons-1 {
    background-color: #00a5a5;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    line-height: 1.15385;
    margin: 0;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    left: 15.6%;
  } */
  /* .buttons:hover
   {
    background-color: #07c;
  } */
  
  .carousel-buttons:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  }
  
  .carousel-buttons:active {
    background-color: #00a5a5;
    box-shadow: none;
  }



  


  /* @media (min-width:600px) {
    
    .caraousel-content{

        max-width: 900px;
        max-height: 350px;
    }
    .header-content{
        font-size: 2.4vw;   
    }

    .dot-ele{
        width: 5px;
        height: 5px;
        margin: 3px;
    }

    .dot-ele-active{
        width: 5px;
        height: 5px;
        margin: 3px;
    }
  } */