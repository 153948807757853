.notes-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background: #FFFFFF !important;
    padding: 20px !important;
}

.notes-drawer .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: 'Montserrat';
    background: #F8F8F8 !important;
    border: 1px solid rgba(237, 241, 244) !important;
}

.notes-drawer .cMuiInputBase-colorPrimary{
    background: red !important;
}

.notes-drawer .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:focus {
    border: 1px solid red !important;
}