.login{
    max-height: 100vh;
}
.login .top{
    background: url('../../../Images/MicrosoftTeams-image (3).png') fixed no-repeat;
    padding: 5px;
    min-height: 100vh;
}

.login-form {
    padding: 20px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    position: absolute;
    padding: 20px;
    left: 55%;
    top: 15%;
    /* min-width: 500px; */
    max-width: 40%;
    /* display: grid; */
}

.login-header {
    display: flex;
}

.login-form .MuiInputLabel-outlined.MuiInputLabel-shrink{
    color: rgba(0, 0, 0, 0.54);
}

.login-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:#00A5A5 !important;
}

.login-card-content{
    display: flex;
    flex-direction: column;
}

.login .MuiCard-root{
    background: rgba(145, 235, 235, 0.452) !important;
    border-radius: 9px !important;
}

.login .bottom{
    margin: 20px;
}
