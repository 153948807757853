.main-content {
  display: flex;
  height: auto;
  margin: auto;
}

.list {
  display: flex;
  flex-direction: column;
  font-family: Montserrat !important;
  /* margin-top: 50px; */
}

.list .MuiInput-underline:before,
.MuiInput-underline:after {
  border: none !important;
}

.list label+.MuiInput-formControl {
  background: #1d1d1d !important;
  z-index: -1;
  /* opacity: 0.1 !important;
  box-shadow: 0px 4px 4px rgba(242, 244, 248, 0.25) !important; */
  border-radius: 6px !important;
}

.list .MuiInputBase-inputTypeSearch{
  color: #FFFFFF !important;
}

.list .css-960cb9-MuiSvgIcon-root{
  font-size: 1.25em !important;
  margin-right: 10px;
}

.list a,
.list .list-item {
  font-size: 16px;
  width: 100%;
  display: flex !important;
  align-items: center;
  padding: 10px 0 !important;
  text-decoration: none;
  color: #FFFFFF;
}

.list a:hover{
  color: #e0e0e0;
  text-decoration: none;
}

.list .list-item {
  cursor: pointer;
  color: #FFFFFF !important;
}

.list .item-text{
  display: flex;

}

.collapse-list{
  color: white !important;
}

.list .list-item{
  display: flex;
  justify-content: space-between;
}


a .link-logo{
  margin-right: 10px;
  /* color: #FFFFFF; */
  min-width: 0 !important;
}

.list .link-logo {
  min-width: 0 !important;
  color: #FFFFFF !important;

}

.list .link-logo .css-i4bv87-MuiSvgIcon-root{
  font-size: 1.25em;
  margin-right: 10px;
}



.sidebar {
  min-height: 100vh;
  padding: 15px;
  background: #000000;
  box-shadow: 0px 4px 21px rgba(221, 222, 226, 0.56);
  overflow: hidden;
  transition: .3s;
  z-index: 2;
}

.list .css-1plgs3k-MuiFormControl-root{
  background: #191919 !important;
  border-radius: 15px !important;
  margin: 25px 0px;
}

.list .MuiInputBase-root{
  color: white !important;
  border-color: transparent !important;
  font-family: 'Montserrat' !important;
}

 .icons {
  color: white;
  position: absolute;
  border: 1px solid black;
  background: #1F1E1E;
  border-radius: 50%;
  z-index: 2;
}

.options>a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #423A3A;
}


.content {
  z-index: 1;
  /* min-width: %; */
}

.sidebar .MuiDivider-root{
  margin:10px 0;
  background-color:#999999;
}

.static-navbar {
  margin-bottom: 20px;
  padding: 0 0 0 50px;
  width: 85vw;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 14px 7px 20px 4px rgb(40 41 61 / 8%)
}

.MuiPaper-root{
  background-color: transparent !important;
}

/* .react-joyride__spotlight{
  position: relative !important;
} */

/* Tour steps css override */
/* #react-joyride-step-2 + #react-joyride-portal .react-joyride__spotlight{top: 7% !important; } 
#react-joyride-step-3 + #react-joyride-portal .react-joyride__spotlight{top: 20% !important;}
#react-joyride-step-4 + #react-joyride-portal .react-joyride__spotlight{top: 37% !important;}
#react-joyride-step-5 + #react-joyride-portal .react-joyride__spotlight{top: 52% !important;}
#react-joyride-step-6 + #react-joyride-portal .react-joyride__spotlight{top: 17% !important; background-color: #0c6e26 !important;}  */

/* Executive steps css override */
