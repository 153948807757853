.top-section-dashboard,
.my-scenarios,
.first-section{
    /* margin-bottom: %; */
    padding: 15px 15px;
    background: #FFFFFF;
    box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.08);
    border-radius: 6px;
    font-family: 'Montserrat';
}

.my-notes-and-beagle {
    width: 25%;
    margin-top: 1%;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 2%;
}

.my-notes-and-beagle .beagle{
    display: flex;
    align-items: center;
}

.my-notes-and-beagle .notes{
    display: flex;
    align-items: center;
}

.chart-tips {
    padding: 15px 10px;
    margin-bottom: 1%;
    border-radius: 6px;
    font-family: 'Montserrat';
    display: flex;
    justify-content: space-between;
}

.chart-tips .chart {
    padding: 15px;
    min-width: 72.5%;
    background: #FFFFFF;
    /* box-shadow: 0px 0px 1px rgba(227, 230, 235, 0.48), 0px 0px 4px #E3E6EB; */
    border-radius: 8px;
}

.chart-tips .business-tips {
    margin-left: 10px;
    padding: 15px;
    min-width: 26%;
    background: #FFFFFF;
    /* box-shadow: 0px 0px 1px rgba(227, 230, 235, 0.48), 0px 0px 4px #E3E6EB; */
    border-radius: 8px;
}

/* .notes-panel{
    position: absolute;
} */

.rgm-equations {
    margin-top: 20px;
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.rgm-modules {
    overflow: auto;
    padding: 5px;
    display: flex;
    align-items: center;
}

.rgm-module {
    min-height: 230px !important;
    max-height: 230px !important;
    width: 20%;
    min-width: 225px;
    box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
    border-radius: 11px;
}

.rgm-module+.rgm-module {
    margin-left: 10px;
}


.module-stable {
    background: rgba(202, 246, 246, 0.4) !important;
}

.module-warning {
    background: #FFE5E5 !important;
}


.first-section .first-card {
    min-height: 315px !important;
    height: 100%;
    background: #00A5A5 !important;
    align-items: center !important;
    border-radius: 10px !important;
}

.first-section .second-card {
    background: #FFE5E5 !important;
    border-radius: 10px !important;
}

.first-section .third-card,
.first-section .fourth-card {
    background: rgba(202, 246, 246, 0.4) !important;
    border-radius: 10px !important;
}

.first-section .card-content {
    display: flex !important;
    flex-direction: column !important;
    font-family: 'Montserrat' !important;
}

.first-section .add-widget-icon {
    font-size: 100px;
    color: #FFFFFF !important;
    margin: auto !important;
}

.data-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #D9D9D9;
    width: 80%;
    border-radius: 20px;
}

.data-navigation .navlink {
    text-align: center;
    width: calc(100%/6);
    cursor: pointer !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 15px !important;
    color: #2D2A2B !important;
    font-family: 'Montserrat' !important;
}

.data-navigation .active-navlink {
    background: #00A5A5;
    color: #FFFFFF !important;
    border-radius: 15px;
    padding: 12px 0;
}

.my-scenarios-table {
    margin-top: 20px;
}

.table-cell {
    background: rgba(230, 236, 240, 0.4) !important;
    border: 2px solid #FFFFFF !important;
    font-family: 'Montserrat' !important;
}

.first-cell {
    text-decoration: underline !important;
    text-align: left !important;
}

.data-cell {
    background: #F9F9F9 !important;
    text-align: center !important;
}

.MuiTableCell-body {
    min-width: 115px !important;
}

.ui.progress{
    margin: 0 !important;
}

/* .header{
    display: flex;
} */

.ui.tiny.progress{
    margin-top: 20px !important;
}

.ui.green.progress .bar {
    background-color: #00A5A5 !important;
}

.ui.red.progress .bar {
    background-color: #E92222 !important;
}

.testFont{
    font-weight: 700 !important;
    font-size: 18px !important;
    color: "Success/500" !important;
}

.container {
    width:100%;
    height: 335px;
    overflow: hidden;
}
.modules {
    height:350px; /* 40px - more place for scrollbar, is hidden under parent box */
    padding:5px;
    white-space:nowrap;
    overflow-x: scroll;
    overflow-y: hide;
	-webkit-overflow-scrolling:touch;
}
.module {
    display:inline-block;
    width:300px;
    height:290px;
    line-height:50px;
    /* text-align:center; */
    background: transparent !important;
}
.module + .module {
    margin-left:20px
}