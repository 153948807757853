.tools-homepage {
    width: 100%;
    /* padding: 15px; */
}

.tools-homepage .top-profile {
    padding: 0 10px 0 35px !important;
    display: flex;
    margin-bottom: 5%;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    background: #F8F8F8;
    box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48)
}

.features,
.properties,
.view-tool-tab,
.testimonials,
.view-more-tools-tab {
    padding: 0 10px 0 35px !important;
    margin-bottom: 5%;

}

.glider {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.glides {
    box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
    border: 1px solid transparent;
    border-radius: 8px;
    min-width: 45%;
    height: auto;
    transition: 0.5s;
}

.top-profile .left .buttons {
    margin-top: 30px;
    display: flex;
}

.top-profile .left .watch-demo-button {

    font-weight: 600 !important;
    font-size: 16px !important;
    background: #FFFFFF !important;
    border: 1px solid #00A5A5 !important;
    border-radius: 10px !important;
    color: #00A5A5 !important;
}

.top-profile .left .view-tool-button {
    font-weight: 600 !important;
    font-size: 16px !important;
    background: #00A5A5;
    color: #FFFFFF !important;
    border-radius: 10px !important;
    margin-left: 15px !important;
}

.video-responsive {
    margin-top: 15px;
}

.feature_list {
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: auto
}


.feature_list .feature {
    min-width: 20%;
    text-align: left;
    /* padding: 0 20px 0 0; */
}


.feature+.feature {
    margin-left: 50px;
}


.feature_list .feature .feature-image {
    width: fit-content;
    padding: 8px;
    background: rgba(0, 165, 165, 0.822);
    /* background: linear-gradient(0deg, rgba(0, 165, 165, 0.05), rgba(0, 165, 165, 0.05)), rgba(0, 165, 165, 0.05); */
    border-radius: 20px;
}

.view-tool-tab {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    background: url("../../../Images/Group\ 7903.png") fixed;
}

.testimonials-nvigation {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.testimonial-link{
    padding: 10px;
    max-height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 200px;
}

.testimonial-link + .testimonial-link{
    margin-left: 20px;
}

.active-testimonial {
    background: linear-gradient(0deg, rgba(0, 165, 165, 0.05), rgba(0, 165, 165, 0.05)), rgba(0, 165, 165, 0.05);
    /* opacity: 0.6; */
    border-radius: 20px;
}