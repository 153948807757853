.MuiAccordionDetails-root {
  flex-direction: column !important;
}

.tools .list-item {
  cursor: pointer;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  color: #2D2A2B !important;
  border-bottom: 1px solid black !important;
  padding: 10px !important;
  font-weight: 400 !important;
  font-size: 10.5px !important;
  display: flex !important;
  align-items: baseline !important;
}

.tools .list-item .MuiTypography-displayBlock{
  font-size: 10.5px !important;
}

.tools .active-list-item {
  font-weight: bolder !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  color: #2D2A2B !important;
  border-bottom: 3px solid black !important;
  border-start-end-radius: 50% !important;
}

.active-list-item .MuiTypography-displayBlock {
  font-weight: bolder !important;
  font-family: 'Montserrat' !important;
}

.card-tool {
  padding: 5px !important;
  max-height: 180px !important;
  /* min-height: 180px !important; */
  border-radius: 10px;
  border-bottom: 1px solid gray;

}

.card-tool .logo-and-name {
  display: flex;
  width: 100%;
  align-items: center;
}

.card-tool .promo-name {
  margin-left: 10px;
}

.card-tool .promo-logo {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  background-color: rgb(18, 167, 174);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-tool .update-and-users {
  font-family: "Montserrat";
  /* margin-top: 5%; */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.card-tool .top-bottom-card-content {
  min-height: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.update-and-users .text-val {
  font-family: "Montserrat" !important;
}

.card-tool .users {
  display: flex;
}


.promo-content {
  display: grid;
  gap: 5%;
  padding: 5px;
  grid-template-columns: repeat(2, 45%);
  /* grid-template-rows: repeat(auto); */
  justify-content: space-between;
}

.promo-icons {
  width: 25px;
  height: 25px;
}

.promo-name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #191919;
  margin-top: 2%;
}

.promo-oneLiner {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #666666;
  margin-top: 2%;
}

.MuiTypography-body1 {
  font-family: 'Montserrat' !important;
}


.search-view-bar {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  align-items: center;
}

.tool-search-bar .MuiInputBase-input {
  font-family: 'Montserrat' !important;
}

.inputSearch input {
  font-family: 'Montserrat' !important;
  height: 40px !important;
  width: 350px !important;
  padding: 0 10px;
  border-radius: 8px !important;
}

.view-tab {
  display: flex;
}

.view-icon {
  cursor: pointer;
  padding: 5px !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 5px !important;
  align-items: center;
}

.active-icon {
  background: rgba(167, 255, 255, 0.2);
  border-radius: 8px;
  border: 0px
}

.view-icon+.view-icon {
  margin-left: 10px;
}

.mosiac-glider{
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.mosiac-glide{
  box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
  border: 1px solid transparent;
  border-radius: 8px;
  min-width: 100%;
  height: auto;
  transition: 0.5s;
}

.mosiac-flex {
  display: flex;
  justify-content: space-between;
}


.mosiac-card {
  border-radius: 9.97109px;
  background: #191919;
  border: 0.997109px solid rgba(4, 165, 150, 0.46);
  box-shadow: 0px 4px 54px rgb(4 165 150 / 47%);
  backdrop-filter: blur(2px);
  height: 200px;
  width: 200px;
  padding: 10px;
  z-index: -1;
}

.right-corner{
  /* Rectangle 456.png */
  background: url('../../../Images/Rectangle 456.png') #FFFFFF fixed no-repeat;
  background-position: center;
  background-size: 400px 400px
}


.bottom-right-corner{
  /* Rectangle 456 (2).png */
  background: url('../../../Images/Rectangle 456 (2).png') #FFFFFF fixed no-repeat;
  background-position: center;
  background-size: 400px 400px
}


.left-corner{
  background: url('../../../Images/Rectangle 456 (1).png') #FFFFFF fixed no-repeat;
  background-position: center;
  background-size: 400px 400px
}

.left-corner {
  border: 0.997109px solid rgba(24, 143, 173, 0.46);
  box-shadow: 0px 4px 54px #188FAD;
}

.center {
  display: flex;
  align-items: center;
  margin: auto;
  /* background: #FFFFFF; */
  background: url('../../../Images/Rectangle 459.png') #FFFFFF fixed no-repeat;
  background-position: center;
  border: 0.997109px solid #F7F7F7;
  backdrop-filter: blur(2px);
  z-index: 1 !important;
}

.bottom-right-corner {
  border: 0.997109px solid rgba(93, 90, 159, 0.9);
  box-shadow: 0px 4px 54px #5D5A9F;
  backdrop-filter: blur(2px);
}


.bottom-left-corner {
  border: 0.997109px solid rgba(63, 115, 172, 0.46);
  box-shadow: 0px 4px 54px #3F73AC;
  backdrop-filter: blur(2px);
  background: url('../../../Images/Rectangle 456 (3).png') #FFFFFF fixed no-repeat;
  background-position: center;
  background-size: 400px 400px
}

.center-text {
  flex: .4;
  margin: auto;
  background: rgb(161 217 212 / 10%);
  border-radius: 30%;
  /* filter: blur(37px); */
  padding: 50px 20px;
}
.mosiac-tools{
  flex: .6;
}

.all-tools{
  display: flex;
  flex-wrap: wrap;
}

.tool-links{
  background: rgba(0, 0, 0, 0.08);
border-radius: 5px;
cursor: pointer;
padding: 5px ;
/* width: 100%; */
}

.tool-links + .tool-links{
  margin-right: 10px;
}