.component{
  font-family: 'Montserrat' !important;
  padding: 0 10px 0 35px;
}

.promotion-data {
    background: rgba(248, 248, 248, 0.4);
    box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
    border-radius: 6px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 3%;
}

.comparision-percentage {
    display: flex;
    align-items: center;
}

.arrow-up-icon {
    color: rgba(18, 167, 174, 1) !important;
    background-color: rgba(18, 167, 174, 0.2) !important;
    border-radius: 50% !important;
    font-size: 30px !important;
}

.recent-scenarios{
    margin-top: 10px;
}

.glider {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .glide {
    box-shadow: 0px 0px 4px #E3E6EB, 0px 0px 1px rgba(227, 230, 235, 0.48);
    padding: 10px !important;
    margin-right: 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    min-width: 45%;
    height: auto;
    transition: 0.5s;
  }


  .pointer-flex{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bullet-icon{
    min-width: 10px !important;
  }

  #moveBehind{
    transform: rotate(180deg);
  }