.background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    /* backdrop-filter: blur(2px); */
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    overflow: hidden;
}
/* 
.closeModalIcon {
    position: absolute;
    top: 1%;
    left: 94%;
    color: white;
    z-index: 3;
    cursor: pointer;
    padding-left: 33em;
    padding-top: 0.5em;
} */


.modal{

    max-width: 39%;
    margin-left: 26%;
  margin-right: auto;

}



/* .modal {
    width: 70rem;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    left: 20%;
    z-index: 10;
    border-radius: 10px;
} */

/* .skip-text {
    position: absolute;
    top: 5%;
    left: 90%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4375px;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
} */

/* .middle-panel {
    text-align: center;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    letter-spacing: 0.4375px !important;
    height: 300px;
} */

.middle-panel{
    background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
    text-align: center;
}

.middle-panel > p {
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 37px;
color: rgb(0, 165, 165);

/* identical to box height */
letter-spacing: 0.4375px;
}

p:nth-child(3){
    font-size: inherit;
font-weight: 450;
line-height: normal;
padding-bottom: 5%;
color:white;
margin-left: 4%;
margin-right: 4%;
}


/* .bottom-panel {
    background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
    padding-top: 11px;
    text-align: center;
    cursor: pointer;
    padding-bottom: 10px;
} */

.bottom-panel{
    background: linear-gradient(180deg, #01181D 0%, #003B3F 100%);
    text-align: center;
    padding: 2.5%;

}

.summary-panel > div{
    font-size: medium;
    font-family: 'Montserrat';
    font-weight: 600;
}

